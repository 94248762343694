import Lottie from "lottie-react";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { http } from "../../API";
import { MenuCard } from "../../components/MenuCard";
import glow from "../../images/Ellipse 50.svg";
import { ReactComponent as RefreshIcon } from "../../images/refreshIcon.svg";
import { Button } from "../../UI/Button";
import styles from "./index.module.css";
import loader from "./loader.json";
import menuError from "./menuError.svg";

export interface MenuType {
  day: string;
  id: number;
  meals: {
    meal: string;
    dishes: {
      name: string;
      hasRecipe: boolean;
      image: string;
      portion: {
        weight: number;
        calories: number;
        proteins: number;
        fats: number;
        carbohydrates: number;
      };
    }[];
  }[];
}

const MenuPage: FC = () => {
  const [menu, setMenu] = useState<MenuType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(true);
  const navigate = useNavigate();

  const getMenu = async () => {
    setLoading(true);
    try {
      const response = await http.get("/api/menu/get_current_menu");
      setMenu(response.data);
      setError(false);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const generateNew = async () => {
    setLoading(true);
    try {
      const response = await http.post("/api/menu/generate_menu");
      setMenu(response.data);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      if (menu !== null) {
        setTimeout(() => {
          setAnimation(false);
        }, 1000);
        setTimeout(() => {
          setError(false);
          setAnimation(true);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    getMenu();
    if (menu?.meals?.length === 0) {
      generateNew();
    }
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <img src={glow} className={styles.glow} />
        <Button
          variant={"primary"}
          size={"large"}
          type={"button"}
          rightIcon={<RefreshIcon />}
          className={`${styles.button} ${loading && styles.activeButton}`}
          onClick={() => generateNew()}
        >
          Сгенерировать новое
        </Button>
      </div>
      {!loading && !error && (
        <div className={styles.menuContainer}>
          {menu?.meals?.map((e, index) => (
            <MenuCard
              time={e.meal}
              dishes={e.dishes}
              key={e.meal + e.dishes[0].name}
              end={index === menu?.meals?.length - 1}
            />
          ))}
        </div>
      )}
      {!loading && error && (
        <div className={styles.animationContainer}>
          <img src={menuError} className={animation ? `${styles.errorIn}` : `${styles.errorOut}`} />
        </div>
      )}
      {loading && !error && (
        <div className={styles.animationContainer}>
          <Lottie className={styles.animation} animationData={loader} />
        </div>
      )}
    </div>
  );
};

export default MenuPage;
